<template>
  <v-content>
    <v-container>
      <v-row>
        <v-col sm="12">
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text class="justify-center">
              <v-layout class="text-center">
                <v-flex xs12>
                  {{message}}
                  <template v-for="(e,idx) in errors">
                    <div :key="idx">
                      {{e}}
                      <br />
                      <br />
                    </div>
                  </template>
                </v-flex>
              </v-layout>
              <v-progress-linear color="primary" indeterminate v-if="isRemoving"></v-progress-linear>
            </v-card-text>
            <v-card-actions>
              <v-btn v-if="success" to="/login" class="primary">Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>
<script>
import { authComputed } from '@state/helpers'
export default {
  page: {
    title: 'Confirm Email',
  },
  data() {
    return {
      message: '',
      isRemoving: true,
      errors: [],
      success: false,
    }
  },
  created() {
    let email = this.$route.query.email
    this.message = `Confirming account for ${email}`
    this.addUser(email)
  },
  computed: {
    ...authComputed,
  },
  methods: {
    addUser(email) {
      this.errors = []
      let token = this.$route.query.token
      const self = this
      if (email && token) {
        axios
          .get(
            `${
              this.identityUrl
            }/api/Auth/ConfirmEmail?email=${encodeURIComponent(
              email
            )}&token=${token}`
          )
          .then((response) => {
            this.isRemoving = false
            this.message = `Account for ${email} has been confirmed`
            this.success = true
          })
          .catch((error) => {
            this.isRemoving = false
            this.message = `There was an error confirming account for ${email} -`
            let err = e.response.data
            if (JSON.stringify(err).substring(0, 1) != '{') {
              self.errors.push(err)
            } else {
              Object.keys(err).forEach((val) => {
                self.errors.push(err[val][0])
              })
            }
          })
      } else {
        this.isRemoving = false
        this.message = `Unable to confirm account`
      }
    },
  },
}
</script>

<style>
</style>
